<template>
  <div class="is-full-height has-background-black">
    <div class="is-full-height">
      <back-button class=""/>
     
      <h1 class="has-text-light">Scan</h1>

      <QRCodeScanner
        style="width: 100%;"
        @result="onScan"
        :switchCameraTrigger="switchCameraTrigger"
        :switchFlashTrigger="toogleFlashTrigger"
      />

      <div style="width: 100%; display: flex; flex-direction: row; justify-content: center; position: absolute; bottom: 2em">
        <button class="button has-background-dark has-text-light" @click="switchCamera">change camera</button>
        <button class="button ml-1 has-background-dark" @click="toggleFlash">💡</button>
        <MiniStack 
          :imageUrls="$store.state.data.scannedItems.map((i)=>i.pictures?.[0])"
          @ministackswiped="$store.commit('popData',{key:'scannedItems'})"
          @click="handleMiniStackClicked"
        />
      </div>

    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from '@vue/runtime-core';
import { useStore } from "vuex";
import { useRouter } from "vue-router"
import  QRCodeScanner from '@/components/ItemForm/QRCodeScanner.vue';
import MiniStack from '@/components/Template/MiniStack';

export default {
  name: 'SearchItems',
  components: {
    QRCodeScanner,
    MiniStack,
  },
  setup(){
    const store = useStore();
    const router = useRouter();

    let minimumTimeBetweenScans = 1500;

    const switchCameraTrigger = ref(0);
    const toogleFlashTrigger = ref(0);

    onMounted(()=>{
      store.dispatch("hideTabBar");
    })
    onUnmounted(()=>{
      store.dispatch("showTabBar");
    })

    // function startNFCScanModeIfPossible(){
    //   if ('NDEFReader' in window) { 
    //     const ndef = new window.NDEFReader();
    //     // start scan
    //     ndef.scan().then(() => {
    //       console.log("NDEF scan started successfully.");
    //       ndef.onreadingerror = () => {
    //         console.log("Cannot read data from the NFC tag. Try another one?");
    //       };
    //       ndef.onreading = event => {
    //           const message = event.message;
    //           for (const record of message.records) {
    //             console.log("Record type:  " + record.recordType);
    //             console.log("MIME type:    " + record.mediaType);
    //             console.log("Record id:    " + record.id);
    //             switch (record.recordType) {
    //               case "text":
    //                 console.log("nfc text record found",record);
    //                 // TODO: Read text record with record data, lang, and encoding.
    //                 break;
    //               case "url":
    //               console.log("nfc url record found",record);
    //                 // TODO: Read URL record with record data.
    //                 break;
    //               default:
    //               console.log("nfc other record found",record);
    //                 // TODO: Handle other records with record data.
    //             }
    //           }
    //         };
    //     }).catch(error => {
    //       console.log(`Error! Scan failed to start: ${error}.`);
    //     }); 
    //   }
    //   else{
    //     console.log("NO NDEF READER available.")
    //   }
    // }

    let lastScan = Date.now();
    const onScan = async (result)=>{
      let timestamp = Date.now()
      if (lastScan && (timestamp - lastScan < minimumTimeBetweenScans)){
        return
      }
      // nice to have: fetch 2nd return value and check what type of code (qr, barcode, ean etc.)
      let itemId;
      if (result.startsWith('http')){
        itemId = result.split('/items/')[1]?.split('?')[0]; // get ID from qr code
        if (!itemId){
          store.dispatch("triggerToast",{message:result+" is not a valid dingsda item. Did you scan the right code?"})
          return
        }
      }
      else{
        itemId = result;
      }
      lastScan = timestamp;
      let scannedItem = await store.dispatch('getItem',itemId);
      if (!scannedItem) store.dispatch("triggerToast",{message:"Could not find the item you scanned."})
      if (scannedItem && store.state.data.scannedItems.findIndex((i)=>i.id == scannedItem.id) === -1){
        store.commit("pushData",{key:'scannedItems',value:scannedItem});
      }
      else {
        store.dispatch("triggerToast",{message:"item already scanned."})
      }
    }

    const handleMiniStackClicked = ()=>{
      // TODO: MOVE TO SCANBASKET VIEW (with Buttons new scan and select to select all to add to basket)
      router.push('/ScannedItems')
    }

    const switchCamera = ()=>{
      switchCameraTrigger.value=switchCameraTrigger.value + 1;
      
      //startNFCScanModeIfPossible()
    }

    const toggleFlash = ()=>{
      toogleFlashTrigger.value=toogleFlashTrigger.value + 1;
      console.log("flash button click",toogleFlashTrigger.value);
    }

    return {handleMiniStackClicked,onScan,switchCamera,switchCameraTrigger,toogleFlashTrigger,toggleFlash}
  }
}
</script>

